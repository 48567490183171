import { post, get } from '@/utils/http'

let devUrl = ''
// if (process.env.NODE_ENV == 'development') {
// 	//http://192.168.3.54:8007
//    devUrl = 'http://192.168.3.164:7300/mock/6164f5051baaf000215cb72e';
// } else {
// 	devUrl = ''
// }
//定制化分页(数据平台)
export function data_page(params) {
    return post({ url: `${devUrl}/service-insight/customer-requirement/data/page`, params })
}
//提交需求/项目(数据平台)
export function data_submit(params) {
    return post({ url: `${devUrl}/service-insight/customer-requirement/data/submit`, params })
}
//类型下拉
export function data_types(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/types`, params })
}

//权益明细
export function data_config_detail(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/config/detail`, params })
}

//进展下拉
export function data_processStatus(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/process-status`, params })
}

//定制化详情
export function data_detail(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/detail`, params })
}
//补充需求提交
export function data_add(params) {
    return post({ url: `${devUrl}/service-insight/customer-requirement/data/add`, params })
}

//获取评估
export function data_require_detail(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/require/detail`, params })
}

//反馈提交
export function data_feedback_submit(params) {
    return post({ url: `${devUrl}/service-insight/customer-requirement/data/feedback/submit`, params })
}

//获取反馈
export function data_feedback_detail(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/feedback/detail`, params })
}
//下载最新成果
export function data_detail_downloadUrl(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/detail/downloadUrl`, params })
}

//结案
export function data_close(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/close`, params })
}

//是否有终止按钮
export function data_hasTermination(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/has-termination`, params })
}
//终止
export function data_termination(params) {
    return post({ url: `${devUrl}/service-insight/customer-requirement/data/termination`, params })
}
//确认开发
export function data_confirm(params) {
    return post({ url: `${devUrl}/service-insight/customer-requirement/data/confirm`, params })
}

//权益使用记录

export function data_config_historyPage(params) {
    return post({ url: `${devUrl}/service-insight/customer-requirement/data/config/history-page`, params })
}

//是否有终止按钮
export function data_config_hasConfig(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/config/hasConfig`, params })
}

//是否有终止按钮
export function data_config_hasValueConfig(params) {
    return get({ url: `${devUrl}/service-insight/customer-requirement/data/config/hasValueConfig`, params })
}
