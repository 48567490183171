<template>
	<div class="customerHome customerOrderRead">
		<div class="chuck">
			<div class="title">
				查看需求
			</div>
			<Form style="margin-top: 20px;" ref="requireForm" :model="requireForm" :label-width="120" label-colon
				inline>
				<FormItem style='width: 40%;' prop="requireName">
					<div class="labelClass" slot='label'>
						需求/项目名称:
					</div>
					<div class="formText">
						{{requireForm.requireName}}
					</div>
				</FormItem>
				<FormItem style='width: 20%;' prop="requireType">
					<div class="labelClass" slot='label'>
						类型:
					</div>
					<div class="formText">
						{{requireForm.requireType}}
					</div>
				</FormItem>
				<FormItem style='width: 20%;' prop="userNickname">
					<div class="labelClass" slot='label'>
						提交人:
					</div>
					<div class="formText">
						{{requireForm.userNickname}}
					</div>
				</FormItem>
			</Form>

			<Form class="formChuck" ref="requireForm" :model="requireForm" :label-width="120" label-colon>
				<FormItem style='width: 100%;' prop="requireText">
					<div class="labelClass" slot='label'>
						描述:
					</div>
					<div style="white-space: pre-wrap;font-size: 14px;">{{requireForm.requireText}}</div>
				</FormItem>
				<FormItem prop="requireApplyTime">
					<div class="labelClass" slot='label'>
						提交时间:
					</div>
					<div class="formText">
						{{requireForm.requireApplyTime | formateTime}}
					</div>

				</FormItem>
				<FormItem v-if='requireForm.requireFileUrl'>
					<Button style="color: #00B6FF;padding: 0;font-size: 14px;" type="text"
						@click="downLoad(requireForm.requireFileUrl)">下载附件</Button>
				</FormItem>
			</Form>
			<Form class="formChuck" v-for="(item,index) in requireForm.customerRequirementInfoAddList"
				:key="`${index}-${item.gmtCreate}`" label-colon :label-width="120" >
				<FormItem prop="requireText">
					<div class="labelClass" slot='label'>
						补充需求:
					</div>
					<div style="white-space: pre-wrap;overflow-wrap:anywhere;font-size: 14px;">{{item.requireText}}
					</div>
				</FormItem>
				<FormItem prop="gmtCreate">
					<div class="labelClass" slot='label'>
						提交时间:
					</div>
					<div class="formText">
						{{item.gmtCreate | formateTime }}
					</div>
				</FormItem>
				<FormItem v-if="item.fileUrl">
					<Button style="color: #00B6FF;padding: 0;font-size: 14px;" type="text"
						@click="downLoad(item.fileUrl)">下载附件</Button>
				</FormItem>
			</Form>
			<Form v-if='requireForm.requireFinishReason' class="formChuck" ref="requireForm" :model="requireForm"
				:label-width="120" label-colon >
				<FormItem class='item' prop="requireFinishReason">
					<div class="labelClass" slot='label'>
						终止原因:
					</div>
					<div style="white-space: pre-wrap;overflow-wrap:anywhere;font-size: 14px;">
						{{requireForm.requireFinishReason}}
					</div>
				</FormItem>
			</Form>
			<Button class="btn" type="primary" @click="back()">确定</Button>
		</div>
	</div>
</template>

<script>
	import {
		data_detail
	} from "@/api/customerApi.js";
	import util from '../../utils/utils2.js'
	export default {
		name: 'customerOrderRead',
		filters: {
			formateTime(e) {
				let time = util.formatDateFmt(
					new Date(e),
					"yyyy-MM-dd hh:mm:ss"
				)
				return time
			}
		},
		data() {
			return {
				requireForm: {}
			}
		},
		mounted() {
			this.init()
		},
		computed: {
			requireUid() {
				return this.$route.query.requireUid
			}
		},
		methods: {
			init() {
				this.getDetail()
			},
			async getDetail() {
				let data = await data_detail({
					requireUid: this.requireUid
				})
				this.requireForm = data
			},
			downLoad(url) {
				window.open(url)
			},
			back() {
				this.$router.push({
					path: '/customerorder/customerorder',
					query: {
						tab: 2
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/style/customerorder.scss";

	.btn {
		display: inline-block;
		width: 140px;
		height: 40px;
		position: relative;
		left: 50%;
		transform: translate(-50%);
	}

	.chuck {
		min-height: calc(100vh - 120px);
	}

	.formChuck {
		margin: 20px 0;
		border: 1px solid rgba(203, 207, 216, 0.2);
		padding: 10px 0;
		border-radius: 8px;

	}

	.labelClass {
		font-weight: 600;
		font-size: 14px;
	}

	.formText {
		font-size: 14px;
	}

	/deep/.ivu-form-item {
		margin-bottom: 0px;
	}
</style>
